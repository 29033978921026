const darkBlue = "#072F5D";
const blue = "rgb(12,40,82)";
const lightBlue = "#D3E9EF";

const veryLightBlue = "rgb(220, 226, 235)";
const popupBorderBlue = "rgb(186,197,214)";
const dottedBlue = "rgb(176,192,216)";
const yellow = "#FFD800";
const red = "#E24A21";
export const colors = {
    darkBlue,
    blue,
    lightBlue,
    veryLightBlue,
    whiteBlue: "#EDF2F7",
    popupBorderBlue,
    dottedBlue,
    yellow,
    red,
};
export const mobileBreakpoint = 800;
export const smallMobileBreakpoint = 600;

export const mobile = `@media (max-width: ${mobileBreakpoint}px)`;
export const smallMobile = `@media (max-width: ${smallMobileBreakpoint}px)`;

export const fontWeights = {
    bold: "600",
};

export const fontSizes = {
    small: "0.9em",
    extraSmall: "0.8em",
    h2: "1.5em",
};

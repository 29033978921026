import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import CircleChart from "./CircleChart";
import createHulls from "../modules/create-hulls";
import {
    setTsneData,
    selectDataForBackend,
    selectActiveWeighting,
} from "../ducks";
import random from "lodash/random";
import { mobile } from "../modules/theme";
import PromoBanner from "./PromoBanner";

const getClustersToCreate = numOfActiveWeightings => {
    const minClusters = 4;
    const maxClusters = 15;
    const clustersToCreate = Math.pow(2, numOfActiveWeightings) * 2;
    if (clustersToCreate > maxClusters) return maxClusters;
    if (clustersToCreate < 4) return minClusters;
    return clustersToCreate;
};
function VisArea(props) {
    const {
        dataToSend,
        setTsneData,
        tsneData,
        method,
        activeWeightings,
        shouldRender = true,
        embedded,
    } = props;
    const [tsneHulls, setTsneHulls] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const [loadingClusters, setLoadingClusters] = React.useState(null);
    const [randomData, setRandomData] = React.useState();
    const intervalId = useRef(null);
    const reductionFetchController = useRef(null);
    const clusterFetchController = useRef(null);

    useEffect(() => {
        // netlify-dev server crashes when cancelling requests
        if (process.env.NODE_ENV === "production") {
            if (loading) {
                reductionFetchController.current.abort();
            }
            if (loadingClusters) {
                clusterFetchController.current.abort();
            }
        }
        setLoading(true);
        setLoadingClusters(true);

        reductionFetchController.current = new AbortController();
        clusterFetchController.current = new AbortController();
        fetch(`/.netlify/functions/index?method=${method}`, {
            method: "POST",
            signal: reductionFetchController.current.signal,
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(dataToSend),
        })
            .then(x => x.json())
            .then(async data => {
                setTsneData(data);
                setRandomData(data);
                setLoading(false);

                const { clusters, dataWithClusterId } = await fetch(
                    `.netlify/functions/cluster?maxClusters=${getClustersToCreate(
                        activeWeightings.length
                    )}`,
                    {
                        method: "POST",
                        signal: clusterFetchController.current.signal,
                        headers: new Headers({
                            "Content-Type": "application/json",
                        }),
                        body: JSON.stringify(data),
                    }
                ).then(x => x.json());

                const hulls = createHulls(clusters);
                setTsneData(dataWithClusterId);
                setTsneHulls(hulls);
                setLoadingClusters(false);
            })
            .catch(console.log);
    }, [dataToSend, setTsneData, method]);

    useEffect(() => {
        if (loading) {
            if (tsneData && !intervalId.current) {
                intervalId.current = setInterval(() => {
                    const randomData = tsneData.map(x => {
                        return {
                            ...x,
                            x: random(6.3, -11.5),
                            y: random(17.2, -20),
                        };
                    });
                    setRandomData(randomData);
                }, 1000);
            }
        } else {
            if (intervalId.current) {
                clearInterval(intervalId.current);
                intervalId.current = null;
            }
        }
    }, [loading]);
    const missingData = !tsneData;
    if (!shouldRender) return null;
    return (
        <div
            css={{
                padding: "40px 30px 30px 30px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",

                [mobile]: {
                    padding: 0,
                    minHeight: "0",
                    height: "100%",
                },
            }}
        >
            <div
                css={{
                    display: "flex",
                    flexGrow: 0,
                }}
            >
                <h1
                    css={{
                        textTransform: "uppercase",
                        fontWeight: "normal",
                        textAlign: "left",
                        margin: 0,
                        marginRight: 10,
                        [mobile]: {
                            display: "none",
                        },
                    }}
                >
                    Visualise
                </h1>
            </div>
            <div
                css={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 0,
                    height: "100%",
                }}
            >
                {missingData ? (
                    <div css={{ position: "relative", flexGrow: 1 }}>
                        <div
                            css={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            Loading...
                        </div>
                    </div>
                ) : (
                    <CircleChart
                        tsneData={loading ? randomData : tsneData}
                        loading={loading || loadingClusters}
                        clusterHulls={tsneHulls}
                    />
                )}
            </div>
            <div
                css={{
                    position: "relative",
                    bottom: "-30px",
                    left: "-30px",
                    width: "calc(100% + 60px)",
                    [mobile]: {
                        display: "none",
                    },
                }}
            >
                {embedded == "false" &&
                    <PromoBanner />
                }
            </div>
        </div>
    );
}

// these parts of state are passed in as props
const mapStateToProps = state => {
    return {
        tsneData: state.tsneData,
        method: state.method,
        dataToSend: selectDataForBackend(state),
        activeWeightings: selectActiveWeighting(state),
    };
};

export default connect(mapStateToProps, { setTsneData })(VisArea);

import React, { useEffect, useState } from "react";
import "./App.css";
import SideBarArea from "./components/SideBarArea";
import VisArea from "./components/VisArea";
import RankingArea from "./components/RankingArea";
import { connect } from "react-redux";
import { fetchData, selectSortedData } from "./ducks";
import { useIsMobile } from "./hooks/mobile";
import { colors } from "./modules/theme";
import Title from "./components/Title";
import AddDataPopupContent from "./components/AddDataPopupContent";
import ContactPopupContent from "./components/ContactPopupContent";
import PromoBanner from "./components/PromoBanner";
import PopupWrapper from "./components/PopupWrapper";
import Menu from "./components/Menu";
import SharePopupContent from "./components/SharePopupContent";
import CustomisePopup from "./components/CustomisePopup";

const Popups = ({ popup }) => {
    return (
        <>
            {popup === "add_data" && (
                <PopupWrapper>
                    <AddDataPopupContent />
                </PopupWrapper>
            )}
            {popup === "contact" && (
                <PopupWrapper>
                    <ContactPopupContent />
                </PopupWrapper>
            )}
            {popup === "about" && (
                <PopupWrapper>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Illo cum adipisci labore culpa facilis beatae laudantium
                    obcaecati, ab necessitatibus doloribus odio alias dolore!
                    Quo ea sapiente facilis, distinctio unde nesciunt!
                </PopupWrapper>
            )}
            {popup === "customise" && (
                <PopupWrapper>
                    <CustomisePopup />
                </PopupWrapper>
            )}
            {popup === "share" && (
                <PopupWrapper>
                    <SharePopupContent />
                </PopupWrapper>
            )}
        </>
    );
};

const MobileApp = ({ popup, sidebarBgColor, sliderColor, embedded }) => {
    const tabs = ["configure", "rank", "visualise"];
    const [tab, setTab] = useState(tabs[0]);
    return (
        <div
            css={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
            }}
        >
            <div css={{ background: sidebarBgColor, color: "white" }}>
                <div css={{ padding: 20 }}>
                    <div
                        css={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Title />
                        <Menu />
                    </div>
                    <small
                        css={{
                            fontSize: 9,
                            textTransform: "uppercase",
                        }}
                    >
                        Powered by Small Multiples
                    </small>
                </div>
                <div css={{ display: "flex", justifyContent: "space-between" }}>
                    {tabs.map(x => {
                        return (
                            <button
                                key={x}
                                onClick={() => setTab(x)}
                                css={{
                                    background: sidebarBgColor,
                                    color: "white",
                                    textTransform: "uppercase",
                                    flexGrow: 1,
                                    padding: 10,
                                    border: "none",
                                    outline: "none",
                                    fontSize: "14px",
                                    fontWeight: x === tab && 700,
                                    borderBottom:
                                        x === tab && `2px solid ${sliderColor}`,
                                }}
                            >
                                {x}
                            </button>
                        );
                    })}
                </div>
            </div>

            <SideBarArea shouldRender={tab === "configure"} />
            <RankingArea shouldRender={tab === "rank"} />
            <VisArea shouldRender={tab === "visualise"} />
            <Popups popup={popup} />
            {embedded == "false" &&
                <PromoBanner />
            }
        </div>
    );
};
function App(props) {
    const {
        sortedData,
        popup,
        sheetId,
        sheetRange,
        sidebarBgColor,
        sliderColor,
        embedded,
    } = props;

    const { fetchData } = props;
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile) {
            /// remove adress bar
            window.scrollTo(0, 1);
        }
        fetchData({ sheetId, sheetRange });
    }, [fetchData, isMobile, sheetId, sheetRange]);

    // Could put a loading animation here
    if (!sortedData) {
        return (
            <div
                css={{
                    display: "flex",
                    height: "100vh",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <p>Loading...</p>
            </div>
        );
    }
    if (isMobile) {
        return (
            <MobileApp
                popup={popup}
                sidebarBgColor={sidebarBgColor}
                sliderColor={sliderColor}
                embedded={embedded}
            />
        );
    }

    return (
        <div className="App" css={{ display: "flex", height: "100vh" }}>
            <Popups popup={popup} />
            <SideBarArea />
            <RankingArea />
            <VisArea
                embedded={embedded}
            />
        </div>
    );
}

// these parts of state are passed in as props
const mapStateToProps = state => {
    return {
        sortedData: selectSortedData(state),
        popup: state.popup,
        sheetId: state.sheetId,
        embedded:state.customisedOptions.embedded,
        sheetRange: state.sheetRange,
        sidebarBgColor: state.customisedOptions.sidebarBgColor,
        sliderColor: state.customisedOptions.sliderColor,
    };
};

const mapDispatchToProps = {
    fetchData: fetchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from "react";
import { connect } from "react-redux";
import {
    selectActiveClusterData,
    selectActiveWeighting,
    selectActiveClusterId,
} from "../ducks";
import arrayToSentence from "array-to-sentence";
import { getClusterColor, getClusterName } from "../modules/cluster-colors";
import { scaleQuantile } from "d3";

function ClusterFactoid(props) {
    if (props.activeClusterData === undefined) return null;

    const {
        activeClusterId,
        activeClusterData: { activeClusterMedians, clusterMedians },
    } = props;
    const textArray = props.activeWeightings.map(({ name }) => {
        const scale = scaleQuantile()
            .domain(clusterMedians.map(x => x.mediansByName[name].median)) // pass the whole dataset to a scaleQuantile’s domain
            .range([
                "lower",
                "slightly lower",
                "average",
                "slightly higher",
                "higher",
            ]);
        const clusterVal = activeClusterMedians.mediansByName[name].median;
        const aOrAn = scale(clusterVal) === "average" ? "an" : "a";
        return `${aOrAn} ${scale(clusterVal)} ${name}`;
    });
    return (
        <div>
            <h2
                css={{
                    fontSize: 24,
                    margin: 0,
                    color: getClusterColor(activeClusterId),
                }}
            >
                {getClusterName(activeClusterId)} group
            </h2>

            <p>
                This group has {arrayToSentence(textArray)} compared to other
                groups.
            </p>
        </div>
    );
}

export default connect(state => ({
    activeClusterId: selectActiveClusterId(state),
    activeClusterData: selectActiveClusterData(state),
    activeWeightings: selectActiveWeighting(state),
    hoveredClusterPosition: state.hoveredClusterPosition,
}))(ClusterFactoid);

import React, { useState, useRef } from "react";
import { Edit } from "./Icons";
import qs from "qs";
import Input from "./Input";
import { updateQuery } from "../modules/query-helpers";

export default function Title() {
    const currentQuery = qs.parse(window.location.search.slice(1));
    const [editingTitle, setEditingTitle] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const inputRef = useRef(null);
    return (
        <div
            css={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
            }}
        >
            <div
                css={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                }}
            >
                {editingTitle ? (
                    <Input
                        ref={inputRef}
                        css={{
                            color: "white",
                            borderBottom: "2px solid white",
                            "::placeholder": {
                                color: "white",
                            },
                        }}
                        value={newTitle}
                        onChange={e => setNewTitle(e.target.value)}
                        placeholder="Title"
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                updateQuery({ key: "title", value: newTitle });
                                setEditingTitle("");
                            }
                        }}
                    />
                ) : (
                    <h1
                        css={{
                            color: "#FFFFFF",
                            fontSize: "30px",
                            fontWeight: "300",
                            letterSpacing: "-0.3px",
                            textTransform: "uppercase",
                            textAlign: "left",
                            margin: 0,
                        }}
                    >
                        {newTitle || currentQuery.title || "A new index!"}
                    </h1>
                )}
                <Edit
                    onClick={() => {
                        if (editingTitle) {
                            setEditingTitle(false);
                            setNewTitle("");
                        } else {
                            setEditingTitle(true);
                            if (!newTitle) {
                                setNewTitle(currentQuery.title || "A new index!");
                            }
                        }

                        setTimeout(() => {
                            if (inputRef.current) {
                                inputRef.current.select();
                            }
                        }, 1);
                    }}
                    css={{
                        width: 15,
                        height: 15,
                        marginLeft: 10,
                        cursor: "pointer",
                        flexShrink: 0,
                    }}
                />
            </div>
            {editingTitle && (
                <div css={{ marginBottom: 10 }}>
                    <button
                        onClick={e => {
                            updateQuery({ key: "title", value: newTitle });
                            setEditingTitle("");
                        }}
                        css={{
                            background: "transparent",
                            color: "white",
                            padding: "5px 10px",
                            border: "solid white 1px",
                        }}
                    >
                        Save
                    </button>
                </div>
            )}
        </div>
    );
}

import React from "react";

const Input = React.forwardRef((props, ref) => {
    return (
        <input
            ref={ref}
            css={{
                marginTop: 5,
                border: "none",
                borderBottom: "2px solid black",
                background: "transparent",
                color: "black",
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 50,
                width: "100%",
                outline: "none",
                fontSize: 16,
                height: "100%",
                "::placeholder": {
                    color: "black",
                    fontWeight: "100",
                    fontSize: 16,
                },
                "&:disabled": {
                    opacity: 0.7,
                    borderColor: "grey",
                },
            }}
            {...props}
        />
    );
});

export default Input;

import React, { useState } from "react";
import IndexItem from "./IndexItem";
import { connect } from "react-redux";
import { mobile } from "../modules/theme";
import { setIndividualSliderLessIsBetter as _setIndividualSliderLessIsBetter } from "../ducks";
import {
    setIndividualSliderWeighting as _setIndividualSliderWeighting,
    resetSliders,
} from "../ducks";
import Title from "./Title";
import Menu from "./Menu";

function SideBarArea(props) {
    let {
        sliderWeightings,
        setIndividualSliderWeighting,
        setIndividualSliderLessIsBetter,
        shouldRender = true,
        resetSliders,
        sidebarBgColor,
        sliderColor,
    } = props;
    const [rawSliderValues, setRawSliderValues] = useState(
        sliderWeightings.map(x => Math.abs(parseInt(x.value, 10)))
    );

    const handleAfterSliderChange = (index, value, lessIsBetter) => {
        const valueToMultipleWith = lessIsBetter ? -1 : 1;
        const sliderValueBasedOnToggle = value * valueToMultipleWith;
        setIndividualSliderWeighting({
            index,
            weighting: sliderValueBasedOnToggle,
        });
    };

    if (!shouldRender) {
        return null;
    }
    return (
        <div
            css={{
                borderRight: "2px solid #CCC",
                boxSizing: "border-box",
                padding: "40px",
                backgroundColor: sidebarBgColor,
                color: "white",
                height: "100%",
                minWidth: "357px",
                overflowY: "auto",
                [mobile]: { paddingTop: 22 },
            }}
        >
            <div>
                <div
                    css={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        [mobile]: { display: "none" },
                    }}
                >
                    <Title />
                    <Menu />
                </div>
                <div
                    css={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                        [mobile]: {
                            display: "none",
                        },
                    }}
                >
                    <p><strong><a css={{ color: 'white', textDecoration: 'none' }} href="https://indisee.app">Indisee</a></strong> by </p>
                    <img
                        css={{ width: 26, height: 26, marginRight: 10, marginLeft: 10 }}
                        alt="Logo"
                        src="/logo.png"
                    />
                    <p css={{ margin: 0 }}><a css={{ color: 'white', textDecoration: 'none' }} href="https://smallmultiples.com.au">Small Multiples</a></p>
                </div>
            </div>

            <div>
                <div>
                    <h2
                        css={{
                            color: "#FFFFFF",
                            fontSize: "20px",
                            lineHeight: "24px",
                            textAlign: "left",
                            marginTop: 38,
                            marginBottom: 0,
                            [mobile]: {
                                marginTop: 0,
                            },
                        }}
                    >
                        How important are these metrics?
                    </h2>
                    <button
                        onClick={() => {
                            setRawSliderValues({});
                            resetSliders();
                        }}
                        css={{
                            border: "none",
                            color: "white",
                            background: "none",
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                            cursor: "pointer",
                            marginTop: 20,
                        }}
                    >
                        <span
                            css={{
                                color: "#FFFFFF",
                                fontSize: "16px",
                                lineHeight: "22px",
                                marginRight: 10,
                            }}
                        >
                            Reset
                        </span>

                        <img
                            css={{
                                width: 24,
                                height: 24,
                            }}
                            src="/reset.svg"
                            alt={"reset"}
                        ></img>
                    </button>
                </div>
            </div>
            <div className="ratings-area">
                {sliderWeightings.map((weighting, i) => {
                    return (
                        <IndexItem
                            sliderColor={sliderColor}
                            onSliderChange={value => {
                                setRawSliderValues(rawSliderValues => {
                                    return {
                                        ...rawSliderValues,
                                        [i]: value,
                                    };
                                });
                            }}
                            sliderValue={
                                rawSliderValues[i] !== undefined
                                    ? rawSliderValues[i]
                                    : 0
                            }
                            lessIsBetter={weighting.lessIsBetter}
                            onLessIsBetterChange={value => {
                                handleAfterSliderChange(
                                    i,
                                    rawSliderValues[i] || 0,
                                    value
                                );
                                setIndividualSliderLessIsBetter({
                                    index: i,
                                    lessIsBetter: !weighting.lessIsBetter,
                                });
                            }}
                            onAfterSliderChange={value =>
                                handleAfterSliderChange(
                                    i,
                                    value,
                                    weighting.lessIsBetter
                                )
                            }
                            key={i}
                            index={i}
                            header={weighting["name"]}
                        />
                    );
                })}
            </div>
        </div>
    );
}

// these parts of state are passed in as props
const mapStateToProps = state => {
    return {
        sliderWeightings: state.sliderWeightings,
        sidebarBgColor: state.customisedOptions.sidebarBgColor,
        sliderColor: state.customisedOptions.sliderColor,
    };
};

const mapDispatchToProps = {
    setIndividualSliderWeighting: _setIndividualSliderWeighting,
    setIndividualSliderLessIsBetter: _setIndividualSliderLessIsBetter,
    resetSliders,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarArea);

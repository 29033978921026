import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MaterialMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import { setPopup } from "../ducks";
import { mobile } from "../modules/theme";

function Menu({ setPopup }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const close = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div
                css={{
                    marginRight: -40,
                    [mobile]: {
                        marginRight: -20,
                    },
                }}
            >
                <IconButton
                    aria-label="more"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon css={{ color: "white" }} />
                </IconButton>
            </div>
            <MaterialMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={close}
            >
                <MenuItem
                    onClick={() => {
                        setPopup("about");
                        close();
                    }}
                >
                    About
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setPopup("add_data");
                        close();
                    }}
                >
                    Data Source
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setPopup("share");
                        close();
                    }}
                >
                    Share
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setPopup("customise");
                        close();
                    }}
                >
                    Customise
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setPopup("contact");
                        close();
                    }}
                >
                    Contact
                </MenuItem>
            </MaterialMenu>
        </div>
    );
}
export default connect(null, { setPopup })(Menu);

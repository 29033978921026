import React, { useState, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { getClusterColor } from "../modules/cluster-colors";
import { colors, mobile } from "../modules/theme";
import {
    selectSortedData,
    selectActiveClusterId,
    selectActiveCircle,
    setClickedCircle,
} from "../ducks";
import { get } from "lodash";
import { Cross, Search } from "./Icons";
import Input from "./Input";
import VirtualList from "react-tiny-virtual-list";
import useComponentSize from "@rehooks/component-size";

const RankingListItem = React.memo(function RankingListItem({
    name,
    index,
    title,
    active,
    activeCluster,
    clusterId,
    onClick,
    style,
}) {
    const getBgColor = () => {
        if (active) return "rgba(255,255,255,0.8)";
        if (activeCluster) return colors.whiteBlue;
        return colors.lightBlue;
    };
    return (
        <div
            style={style}
            onClick={onClick}
            css={{
                height: "50px",
                backgroundColor: getBgColor(),
                textAlign: "left",
                borderTop: "2px solid white",
                cursor: "pointer",
            }}
        >
            <div
                css={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    padding: "0 5px",
                }}
            >
                <span
                    css={{
                        width: 10,
                        height: 10,
                        borderRadius: "100%",
                        background: getClusterColor(clusterId),
                        marginRight: 5,
                        flexShrink: 0,
                    }}
                ></span>
                <div css={{ marginRight: 5 }}>
                    {index < 9 ? "0" + (index + 1) : index + 1}
                </div>
                <div>{name}</div>
            </div>
        </div>
    );
});

function RankingArea(props) {
    const {
        sortedData,
        tsneData,
        activeClusterId,
        setClickedCircle,
        shouldRender = true,
        clickedItemIndex,
        lastClickWasFromRanking,
    } = props;
    const [searchTerm, setSearchTerm] = useState("");
    const data = sortedData.filter(x => {
        if (!searchTerm) return true;
        return x.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    const listRef = useRef(null);
    const { height } = useComponentSize(listRef);
    const setHasRendered = useState(false)[1];
    useLayoutEffect(() => {
        // need one extra render to measure things for the virtual list
        if (shouldRender) {
            setHasRendered(true);
        }
    }, [shouldRender, setHasRendered]);
    if (!shouldRender) {
        return null;
    }
    return (
        <div
            css={{
                paddingTop: 40,
                backgroundColor: colors.lightBlue,
                width: 320,
                display: "flex",
                flexDirection: "column",
                minWidth: 300,
                visibility: !shouldRender && "hidden",
                [mobile]: {
                    width: "100%",
                    height: "100%",
                    minHeight: 0,
                    paddingTop: 0,
                },
            }}
        >
            <div css={{ padding: "0 5px" }}>
                <h1
                    css={{
                        textTransform: "uppercase",
                        fontWeight: "normal",
                        textAlign: "left",
                        margin: 0,
                        [mobile]: {
                            display: "none",
                        },
                    }}
                >
                    Rank
                </h1>

                <h2
                    css={{
                        display: "none",
                        fontSize: "20px",
                        lineHeight: "24px",
                        textAlign: "left",
                        marginTop: "22px",
                        marginBottom: "0",

                        fontWeight: "normal",
                        [mobile]: {
                            display: "block",
                        },
                    }}
                >
                    Which ones have the highest scores?
                </h2>
                <div css={{ position: "relative" }}>
                    <Input
                        placeholder="Filter the list"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <div
                        css={{
                            position: "absolute",
                            right: 5,
                            top: 0,
                            bottom: 0,
                            margin: "auto",
                            height: "20px",
                            display: "flex",
                            width: 50,
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        {searchTerm && (
                            <Cross
                                onClick={() => setSearchTerm("")}
                                css={{
                                    width: 10,
                                    marginRight: 5,
                                    cursor: "pointer",
                                }}
                            />
                        )}
                        <Search css={{ width: 20 }} />
                    </div>
                </div>
            </div>
            <div
                ref={listRef}
                css={{
                    marginTop: "30px",
                    flexGrow: 1,
                    "> div": { overflowX: "hidden" },
                }}
            >
                {height > 0 && (
                    <VirtualList
                        width="100%"
                        height={height}
                        itemCount={data.length}
                        overscanCount={50}
                        scrollToAlignment="center"
                        itemSize={50} // Also supports variable heights (array or function getter)
                        scrollToIndex={
                            lastClickWasFromRanking
                                ? undefined
                                : clickedItemIndex
                        }
                        renderItem={({ index, style }) => {
                            const item = data[index];
                            const i = item.index;
                            const keys = Object.keys(item);
                            const name = item[keys[0]];
                            const active = i === clickedItemIndex;
                            const clusterId = get(tsneData, [i, "clusterId"]);
                            return (
                                <RankingListItem
                                    style={style}
                                    title={`Ranking value: ${item.sortValue}`}
                                    key={name}
                                    index={i}
                                    name={name}
                                    active={active}
                                    activeCluster={
                                        clusterId === activeClusterId
                                    }
                                    clusterId={clusterId}
                                    onClick={() => {
                                        setClickedCircle({
                                            index: i,
                                            lastClickWasFromRanking: true,
                                        });
                                    }}
                                />
                            );
                        }}
                    />
                )}
            </div>
        </div>
    );
}

// these parts of state are passed in as props
const mapStateToProps = state => {
    return {
        sortedData: selectSortedData(state),
        activeCircle: selectActiveCircle(state),
        activeClusterId: selectActiveClusterId(state),
        clickedItemIndex: state.clickedItemIndex,
        tsneData: state.tsneData,
        clickedClusterId: state.clickedClusterId,
        lastClickWasFromRanking: state.lastClickWasFromRanking,
    };
};

export default connect(mapStateToProps, {
    setClickedCircle,
})(RankingArea);

import React, { useRef } from "react";
import * as d3 from "d3";
import { useSpring, animated } from "react-spring";
import smoothHull from "../modules/smooth-hull";
import { connect } from "react-redux";
import {
    setHoveredItemIndex,
    setHoveredCluster,
    setClickedCluster,
    selectActiveClusterId,
    selectActiveCircle,
    setClickedCircle,
} from "../ducks";
import { getClusterColor } from "../modules/cluster-colors";
import useComponentSize from "@rehooks/component-size";
import Factoid from "./Factoid";
import { useIsMobile } from "../hooks/mobile";
const circleStroke = 0;
const Circle = props => {
    const {
        d,
        cy,
        cx,
        width,
        height,
        onMouseEnter,
        onMouseLeave,
        onClick,
        circleWidth,
    } = props;
    const fill = getClusterColor(d.clusterId);

    const circleStyle = useSpring({
        to: { cy, cx, fill },
        from: { cx: width / 2, cy: height / 2 },
    });

    return (
        <animated.circle
            css={{ cursor: "pointer" }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            stroke={"black"}
            strokeWidth={circleStroke}
            r={circleWidth / 2}
            fill={circleStyle.fill}
            style={circleStyle}
        />
    );
};

const Hulls = ({
    loading,
    onMouseEnter,
    onMouseLeave,
    xScale,
    yScale,
    clusterHulls,
    activeClusterId,
    onClick,
    activeCircle,
    isMobile,
    margin,
}) => {
    const circleStyle = useSpring({
        opacity: loading ? 0 : 1,
    });
    if (!clusterHulls) return null;

    return (
        <animated.g
            style={circleStyle}
            transform={`translate(${margin.left},${margin.top})`}
        >
            {clusterHulls.map((d, i) => {
                const { clusterId, hull } = d;
                const path = smoothHull(
                    (hull || []).map(d => [xScale(d[0]), yScale(d[1])])
                );
                return (
                    <path
                        d={path}
                        key={clusterId}
                        fill={getClusterColor(clusterId)}
                        stroke={
                            activeClusterId === clusterId
                                ? "black"
                                : getClusterColor(clusterId)
                        }
                        strokeWidth="2"
                        css={{
                            cursor: "pointer",
                            opacity: 0.2,
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            onClick(i);
                        }}
                        onMouseEnter={() => {
                            if (isMobile) return;
                            if (loading) return;
                            onMouseEnter(i);
                        }}
                        onMouseLeave={onMouseLeave}
                    />
                );
            })}
        </animated.g>
    );
};

const Tooltip = ({ activeCircle, xScale, yScale, circleWidth, margin }) => {
    const triangleHeight = 8;
    return (
        <div
            css={{
                width: `calc(100% - ${margin.left +
                    margin.right +
                    circleWidth}px)`,
                height: `calc(100% - ${margin.top +
                    margin.bottom +
                    circleWidth}px)`,
                position: "absolute",
                pointerEvents: "none",
                margin: "auto",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
            }}
        >
            {activeCircle && (
                <div
                    css={{
                        position: "absolute",
                        top: yScale(activeCircle.y),
                        left: xScale(activeCircle.x),
                        transform: `translate(calc(-50% - ${circleWidth /
                            2}px), calc(-100% - ${circleWidth +
                            triangleHeight +
                            circleStroke * 2}px))`,
                        // boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
                        filter: `drop-shadow(0 3px 7px rgba(0, 0, 0, 0.3))`,
                        background: "white",
                        padding: "8px 12px",
                        "&:after": {
                            content: '""',
                            width: 0,
                            height: "0",
                            borderLeft: `${triangleHeight}px solid transparent`,
                            borderRight: `${triangleHeight}px solid transparent`,
                            borderTop: `${triangleHeight}px solid white`,
                            position: "absolute",
                            bottom: "0",
                            left: "50%",
                            transform: "translate(-50%, 100%)",
                        },
                    }}
                >
                    {activeCircle.name}
                </div>
            )}
        </div>
    );
};
function CircleChart({
    tsneData,
    clusterHulls,
    setHoveredItemIndex,
    hoveredItemIndex,
    setHoveredCluster,
    loading,
    rawHoveredItem,
    activeCircle,
    setClickedCluster,
    activeClusterId,
    setClickedCircle,
}) {
    const d3Container = useRef(null);
    const { width: containerWidth, height: containerHeight } = useComponentSize(
        d3Container
    );

    const isMobile = useIsMobile();
    const margin = isMobile
        ? { top: 30, right: 40, bottom: 30, left: 40 }
        : { top: 50, right: 60, bottom: 50, left: 60 };
    const circleWidth = isMobile ? 11 : 16;

    const width = containerWidth - margin.left - margin.right - circleWidth / 2;
    const height =
        containerHeight - margin.top - margin.bottom - circleWidth / 2;

    if (!tsneData) return null;
    const xScale = d3
        .scaleLinear()
        .domain(d3.extent(tsneData, d => d.x))
        .range([circleWidth, width]);
    const yScale = d3
        .scaleLinear()
        .range([circleWidth, height - circleWidth])
        .domain(d3.extent(tsneData, d => d.y));

    return (
        <div
            css={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                cursor: activeCircle || activeClusterId ? "pointer" : "auto",
            }}
            onClick={() => {
                setClickedCircle();
                setClickedCluster();
            }}
        >
            <Factoid loading={loading} />
            <div
                css={{
                    height: "100%",
                    width: "100%",
                    minHeight: 0,
                    flexGrow: 1,
                    position: "relative",
                }}
                ref={d3Container}
            >
                <Tooltip
                    activeCircle={activeCircle}
                    xScale={xScale}
                    yScale={yScale}
                    circleWidth={circleWidth}
                    margin={margin}
                />
                <svg
                    css={{
                        margin: "0 auto",
                        display: "block",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Hulls
                        setHoveredCluster={setHoveredCluster}
                        loading={loading}
                        xScale={xScale}
                        yScale={yScale}
                        isMobile={isMobile}
                        clusterHulls={clusterHulls}
                        margin={margin}
                        onMouseEnter={clusterId => {
                            if (loading) {
                                return;
                            }
                            setHoveredCluster(clusterId);
                        }}
                        onClick={clusterId => {
                            if (loading) {
                                return;
                            }

                            setClickedCluster(clusterId);
                        }}
                        onMouseLeave={e => setHoveredCluster()}
                        activeClusterId={activeClusterId}
                    />
                    <g transform={`translate(${margin.left},${margin.top})`}>
                        {width > 0 &&
                            tsneData.map((d, i) => {
                                return (
                                    <Circle
                                        key={d.name + i}
                                        d={d}
                                        width={width}
                                        height={height}
                                        circleWidth={circleWidth}
                                        cy={yScale(d.y)}
                                        cx={xScale(d.x)}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (loading) {
                                                return;
                                            }

                                            setClickedCircle({ index: i });
                                        }}
                                        onMouseEnter={e => {
                                            if (isMobile) return;
                                            e.stopPropagation();
                                            if (loading) {
                                                return;
                                            }

                                            setHoveredItemIndex(i);
                                        }}
                                        onMouseLeave={e =>
                                            setHoveredItemIndex()
                                        }
                                    />
                                );
                            })}
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default connect(
    state => {
        return {
            activeClusterId: selectActiveClusterId(state),
            activeCircle: selectActiveCircle(state),
        };
    },
    {
        setHoveredItemIndex,
        setHoveredCluster,
        setClickedCluster,
        setClickedCircle,
    }
)(CircleChart);

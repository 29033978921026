import React from "react";
import ItemFactoid from "./ItemFactoid";
import ClusterFactoid from "./ClusterFactoid";
import { connect } from "react-redux";
import { mobile, colors } from "../modules/theme";

const defined = x => x !== undefined;
const Factoid = ({
    hoveredClusterId,
    hoveredItemIndex,
    clickedClusterId,
    clickedItemIndex,
    loading,
}) => {
    const somethingIsActive =
        defined(hoveredItemIndex) ||
        defined(hoveredClusterId) ||
        defined(clickedItemIndex) ||
        defined(clickedClusterId);
    const getContent = () => {
        if (defined(hoveredItemIndex)) {
            return <ItemFactoid />;
        }
        if (defined(hoveredClusterId)) {
            return <ClusterFactoid />;
        }
        if (defined(clickedItemIndex)) {
            return <ItemFactoid />;
        }
        if (defined(clickedClusterId)) {
            return <ClusterFactoid />;
        }
        return <p>Hover or click the visualisation to get more info.</p>;
    };
    return (
        <div
            css={{
                maxHeight: 175,
                paddingTop: 10,
                flexGrow: 1,
                height: 175,
                overflow: "auto",
                [mobile]: {
                    padding: 0,
                },
            }}
        >
            <div
                css={{
                    [mobile]: {
                        padding: 5,
                        background: colors.lightBlue,
                    },
                }}
            >
                <h2
                    css={{
                        display: "none",
                        fontSize: "20px",
                        lineHeight: "24px",
                        textAlign: "left",
                        marginTop: "10px",
                        marginBottom: "5px",
                        fontWeight: "normal",
                        [mobile]: {
                            display: somethingIsActive ? "none" : "block",
                        },
                    }}
                >
                    Which ones are most similar?
                </h2>
                {getContent()}
            </div>
        </div>
    );
};
export default connect(state => {
    return {
        hoveredClusterId: state.hoveredClusterId,
        hoveredItemIndex: state.hoveredItemIndex,
        clickedClusterId: state.clickedClusterId,
        clickedItemIndex: state.clickedItemIndex,
    };
})(Factoid);

import qs from "qs";
import omit from "lodash/omit";
export const updateSliderWeightingQuery = newSliderWeightedData => {
    const currentQuery = qs.parse(window.location.search.slice(1));
    const newQuery = {
        ...currentQuery,
        sliderWeightings: newSliderWeightedData.filter(x => x.value !== 0),
    };
    window.history.pushState(null, null, "?" + qs.stringify(newQuery));
};

export const updateQuery = ({ key, value }) => {
    const currentQuery = qs.parse(window.location.search.slice(1));
    const newQuery =
        value === undefined || value === ""
            ? omit(currentQuery, key)
            : {
                  ...currentQuery,
                  [key]: value,
              };
    window.history.pushState(null, null, "?" + qs.stringify(newQuery));
};

import React from "react";
import Input from "./Input";
import { colors } from "../modules/theme";

function ContactPopupContent() {
    return (
        <div>
            <h2
                css={{
                    background: colors.yellow,
                    textAlign: "center",
                    fontWeight: "normal",
                    fontSize: 24,
                    padding: "20px",
                    marginTop: 0,
                }}
            >
                Ready to make an index or just want to say hi 👋?
            </h2>
            {/* This form is handled by netlfy, if you change it make sure you
            also change the index.html in /public/index.html */}
            <form
                method="POST"
                data-netlify="true"
                name="contact"
                css={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <input type="hidden" name="form-name" value="contact" />
                <div
                    css={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                        flexWrap: "wrap",
                    }}
                >
                    <label
                        css={{ fontWeight: "bold", marginTop: 20, width: 300 }}
                    >
                        Your name
                        <Input
                            type="text"
                            required
                            name={"name"}
                            css={{ paddingRight: 0, fontSize: "2em", }}
                        />
                    </label>
                    <label
                        css={{ fontWeight: "bold", marginTop: 20, width: 300 }}
                    >
                        Email
                        <Input
                            required
                            type="email"
                            name={"email"}
                            css={{ paddingRight: 0, fontSize: "2em", }}
                        />
                    </label>
                </div>

                <label
                    css={{ fontWeight: "bold", marginTop: 20, maxWidth: 300 }}
                >
                    Company Name
                    <Input
                        type="text"
                        name="companyName"
                        css={{ paddingRight: 0, fontSize: "2em", }}
                    />
                </label>

                <label css={{ fontWeight: "bold", marginTop: 20 }}>
                    Message
                    <textarea
                        required
                        name="message"
                        placeholder="Type your message here"
                        rows="7"
                        css={{
                            paddingRight: 0,
                            marginRight: 50,
                            display: "block",
                            marginTop: 10,
                            width: "100%",
                            border: "2px solid black",
                            fontSize: "2em",
                        }}
                    />
                </label>
                <button
                    css={{
                        width: 150,
                        fontSize: "1.5em",
                        background: "white",
                        padding: 10,
                        fontWeight: "bold",
                        fontSize: 14,
                        border: "2px solid black",
                        margin: "25px auto 0 auto",
                        cursor: "pointer",
                    }}
                    type="submit"
                >
                    Send
                </button>
            </form>
        </div>
    );
}

export default ContactPopupContent;

import React from "react";
import { setPopup, setShowBanner } from "../ducks";
import { connect } from "react-redux";
import { Cross } from "./Icons";

function PromoBanner({ setPopup, setShowBanner, showBanner, backgroundColor }) {
    if (!showBanner) return null;
    return (
        <div
            css={{
                background: backgroundColor,
                display: "flex",
                alignItems: "center",
                padding: "20px 30px",
                width: "100%",
                position: "relative",
                maxWidth: 850,
                margin: "0 auto",
            }}
        >
            <Cross
                css={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    width: 25,
                    height: 25,
                    cursor: "pointer",
                    display: "block",
                }}
                onClick={e => setShowBanner(false)}
            />
            <div
                css={{ marginRight:'30px' }}
            >
                <h3>Do you want to visualise the world in a unique way?</h3>
                <p
                    css={{
                        fontSize: "15px",
                        fontWeight: "300",
                    }}
                >
                    To understand large amounts of data, you need to divide it up. You need to see the natural groups that data makes. Is there a cluster of people with moustaches who also enjoy lattes, for example?
                    Indisee will help find these patterns in your data, based on criteria you select.
                </p>
            </div>
            <div
                css={{
                    flexGrow: "1",
                    textAlign: "center",
                }}
            >
                <button
                    onClick={e => {
                        setPopup("contact");
                    }}
                    css={{
                        width: 150,
                        background: "transparent",
                        padding: 10,
                        fontWeight: "bold",
                        fontSize: 14,
                        border: "2px solid black",
                        marginTop: 25,
                        cursor: "pointer",
                    }}
                    type="submit"
                >
                    Get in touch!
                </button>
            </div>
        </div>
    );
}

export default connect(
    state => ({
        showBanner: state.showBanner,
        backgroundColor: state.customisedOptions.sliderColor,
    }),
    {
        setPopup,
        setShowBanner,
    }
)(PromoBanner);

import React from "react";

export function Search(props) {
    return (
        <svg
            aria-hidden="true"
            data-prefix="fal"
            data-icon="search"
            className="prefix__svg-inline--fa prefix__fa-search prefix__fa-w-16"
            viewBox="0 0 512 512"
            {...props}
        >
            <path
                fill="currentColor"
                d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
            />
        </svg>
    );
}

export const Copy = React.forwardRef((props, ref) => {
    return (
        <svg ref={ref} viewBox="0 0 896 1024" {...props}>
            <path d="M128 768h256v64H128v-64zm320-384H128v64h320v-64zm128 192V448L384 640l192 192V704h320V576H576zm-288-64H128v64h160v-64zM128 704h160v-64H128v64zm576 64h64v128c-1 18-7 33-19 45s-27 18-45 19H64c-35 0-64-29-64-64V192c0-35 29-64 64-64h192C256 57 313 0 384 0s128 57 128 128h192c35 0 64 29 64 64v320h-64V320H64v576h640V768zM128 256h512c0-35-29-64-64-64h-64c-35 0-64-29-64-64s-29-64-64-64-64 29-64 64-29 64-64 64h-64c-35 0-64 29-64 64z" />
        </svg>
    );
});
export function InfoIcon(props) {
    return (
        <svg
            aria-hidden="true"
            data-prefix="far"
            data-icon="info-circle"
            className="prefix__svg-inline--fa prefix__fa-info-circle prefix__fa-w-16"
            viewBox="0 0 512 512"
            {...props}
        >
            <path
                fill="currentColor"
                d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
            />
        </svg>
    );
}

export function Cross(props) {
    return (
        <svg
            aria-hidden="true"
            data-prefix="fal"
            data-icon="times"
            className="prefix__svg-inline--fa prefix__fa-times prefix__fa-w-10"
            viewBox="0 0 320 512"
            {...props}
        >
            <path
                fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
            />
        </svg>
    );
}

export function Edit(props) {
    return (
        <svg
            aria-hidden="true"
            data-prefix="fal"
            data-icon="edit"
            className="prefix__svg-inline--fa prefix__fa-edit prefix__fa-w-18"
            viewBox="0 0 576 512"
            {...props}
        >
            <path
                fill="currentColor"
                d="M417.8 315.5l20-20c3.8-3.8 10.2-1.1 10.2 4.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h292.3c5.3 0 8 6.5 4.2 10.2l-20 20c-1.1 1.1-2.7 1.8-4.2 1.8H48c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V319.7c0-1.6.6-3.1 1.8-4.2zm145.9-191.2L251.2 436.8l-99.9 11.1c-13.4 1.5-24.7-9.8-23.2-23.2l11.1-99.9L451.7 12.3c16.4-16.4 43-16.4 59.4 0l52.6 52.6c16.4 16.4 16.4 43 0 59.4zm-93.6 48.4L403.4 106 169.8 339.5l-8.3 75.1 75.1-8.3 233.5-233.6zm71-85.2l-52.6-52.6c-3.8-3.8-10.2-4-14.1 0L426 83.3l66.7 66.7 48.4-48.4c3.9-3.8 3.9-10.2 0-14.1z"
            />
        </svg>
    );
}

import React from "react";

import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import Tooltip from "@reach/tooltip";

const SLIDER_MIN = 0;
const SLIDER_MAX = 5;

const SliderLegend = props => {
    return (
        <span
            css={{
                color: "rgba(255,255,255,0.7)",
                fontSize: "11px",
                fontWeight: "bold",
                lineHeight: "15px",
            }}
        >
            {props.children}
        </span>
    );
};

function IndexItem(props) {
    const {
        header,
        onSliderChange,
        onAfterSliderChange,
        onLessIsBetterChange,
        lessIsBetter,
        sliderValue,
        index,
        sliderColor,
    } = props;

    return (
        <div
            css={{
                marginTop: index === 0 ? 0 : 33,
            }}
            className={"index-item"}
        >
            <header
                css={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3
                    css={{
                        color: "#FFFFFF",
                        fontSize: "18px",
                        lineHeight: "21px",
                        textAlign: "left",
                        fontWeight: "normal",
                    }}
                >
                    {header}
                </h3>
                <Tooltip
                    label={
                        lessIsBetter
                            ? "Lower value ranks higher"
                            : "Higher value ranks higher"
                    }
                    css={{
                        background: "hsla(0, 0%, 0%, 0.75)",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "0.5em 1em",
                    }}
                >
                    <button
                        css={{
                            border: "none",
                            background: "rgba(255,255,255,0.3)",
                            borderRadius: "2px",
                            width: 24,
                            height: 24,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={e => {
                            e.preventDefault();
                            const newlessIsBetterrState = !lessIsBetter;
                            onLessIsBetterChange(newlessIsBetterrState);
                        }}
                    >
                        <img
                            css={{ height: 18 }}
                            alt={"chevron" + lessIsBetter ? "down" : "up"}
                            src={`/noun_chevron_${
                                lessIsBetter ? "down" : "up"
                            }.svg`}
                        ></img>
                    </button>
                </Tooltip>
            </header>
            <div
                className={"index-item-slider-area"}
                css={{
                    display: "flex",
                    alignItems: "center",
                    "& .rc-slider-rail": {
                        background: "rgba(255, 255, 255, 0.3)",
                        borderRadius: "3px",
                    },
                    "& .rc-slider-track": {
                        background: sliderColor,
                        borderRadius: "3px",
                    },
                    "& .rc-slider-handle": {
                        background: sliderColor,
                        border: "2px solid white",
                        height: "14px",
                        width: "14px",
                    },
                }}
            >
                <Slider
                    value={sliderValue}
                    min={SLIDER_MIN}
                    max={SLIDER_MAX}
                    onChange={onSliderChange}
                    onAfterChange={onAfterSliderChange}
                />
            </div>
            <div css={{ display: "flex", justifyContent: "space-between" }}>
                <SliderLegend>Less important</SliderLegend>
                <SliderLegend>More important</SliderLegend>
            </div>
        </div>
    );
}

export default IndexItem;

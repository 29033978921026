import omit from "lodash/omit";

// https://www.statisticshowto.datasciencecentral.com/normalized/
const normaliseValue = (key, value, maxMinValues) => {
    return (
        (value - maxMinValues.minValues[key]) /
        (maxMinValues.maxValues[key] - maxMinValues.minValues[key])
    );
};
const createNormalisedDataObject = (object, maxMinValues) => {
    let ret = {};
    for (let key of Object.keys(object)) {
        if (!isNaN(object[key])) {
            ret[key] = normaliseValue(key, object[key], maxMinValues);
        } else {
            ret[key] = 0;
        }
    }

    return ret;
};

// Get max/min values
const getMaxMinValuesPerDimension = data => {
    let maxValues = {};
    let minValues = {};
    Object.keys(data[0]).forEach(key => {
        maxValues[key] = 0;
        minValues[key] = Number.MAX_SAFE_INTEGER;
    });
    data.forEach(d => {
        Object.entries(d)
            .slice(1)
            .forEach(([key, value]) => {
                if (value > maxValues[key]) {
                    maxValues[key] = value;
                }
                if (value < minValues[key]) {
                    minValues[key] = value;
                }
            });
    });

    return { maxValues: maxValues, minValues: minValues };
};

const normaliseData = data => {
    const maxMinValues = getMaxMinValuesPerDimension(data);
    return data.map(d => {
        const [key, value] = Object.entries(d)[0];
        const objectWithoutName = omit(d, key);
        return {
            name: value,
            ...createNormalisedDataObject(objectWithoutName, maxMinValues),
        };
    });
};

export { normaliseData };

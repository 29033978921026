import { normaliseData } from "../modules/normalise-data";
import { orderBy, omit, keyBy, groupBy, get } from "lodash";
import { createSelector } from "reselect";
import qs from "qs";
import {
    updateSliderWeightingQuery,
    updateQuery,
} from "../modules/query-helpers";
import { median as calculateMedian } from "d3";
import { createSlice } from "@reduxjs/toolkit";
import { colors } from "../modules/theme";
let queryOnLoad = qs.parse(window.location.search.slice(1));

const defaultSheetId = "1ICKFRbJfXu0wKGK5a_71WuJw7cxNWqUCl_bBSRFaQQ8";
const defaultIndexTitle = "Occupations most at risk of COVID-19 in Australia"
const defaultSliderWeightings = "sliderWeightings%5B0%5D%5Bname%5D=Exposure%20to%20disease&sliderWeightings%5B0%5D%5Bvalue%5D=5&sliderWeightings%5B0%5D%5BlessIsBetter%5D=false&sliderWeightings%5B1%5D%5Bname%5D=Proximity%20Score&sliderWeightings%5B1%5D%5Bvalue%5D=5&sliderWeightings%5B1%5D%5BlessIsBetter%5D=false"
const defaultColourScheme = "customisedOptions%5BsidebarBgColor%5D=%23000000"

if (!queryOnLoad.sheetId) {
    window.history.replaceState(
        null,
        null,
        "?" + qs.stringify({ ...queryOnLoad, sheetId: defaultSheetId, title: defaultIndexTitle }) + '&' + defaultSliderWeightings + '&' + defaultColourScheme
    );
}

const defaultCustomisedOptions = {
    sidebarBgColor: colors.darkBlue,
    sliderColor: colors.yellow,
    embedded: queryOnLoad.embedded || "false",
};
const initialState = {
    loading: true,
    sliderWeightings: queryOnLoad.sliderWeightings,
    sheetId: queryOnLoad.sheetId || defaultSheetId,
    sheetRange: queryOnLoad.sheetRange,
    popup: undefined,
    hoveredItemIndex: undefined,
    tsneData: undefined,
    hoveredClusterId: undefined,
    clickedItemIndex: undefined,
    clickedClusterId: undefined,
    method: "umap",
    lastClickWasFromRanking: false,
    showBanner: true,
    customisedOptions: {
        ...defaultCustomisedOptions,
        ...queryOnLoad.customisedOptions,
    },
};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        dataRequest(state, action) {
            return { ...state, loading: true };
        },
        dataSuccess(state, action) {
            const withName = action.payload.map(d => {
                const [key, value] = Object.entries(d)[0];
                const objectWithoutName = omit(d, key);
                return { name: value, ...objectWithoutName };
            });
            const rawDataByName = keyBy(withName, "name");

            const ordered = orderBy(withName, "name");
            return {
                ...state,
                loading: false,
                rawDataByName: rawDataByName,
                normalisedData: normaliseData(ordered),
                sliderWeightings: Object.keys(action.payload[0])
                    .slice(1)
                    .map((name, i) => {
                        const weightingFromQuery = (
                            state.sliderWeightings || []
                        ).find(x => x.name === name);
                        if (weightingFromQuery) {
                            return {
                                name,
                                value: parseInt(weightingFromQuery.value, 10),
                                lessIsBetter:
                                    weightingFromQuery.lessIsBetter === "true",
                            };
                        }
                        return {
                            name,
                            value: i === 0 ? 5 : 0,
                            lessIsBetter: false,
                        };
                    }),
            };
        },
        setShowBanner(state, action) {
            return { ...state, showBanner: action.payload };
        },
        setTsneData(state, action) {
            return { ...state, tsneData: action.payload };
        },
        setClickedCircle(state, action) {
            return {
                ...state,
                clickedItemIndex:
                    state.clickedItemIndex === get(action.payload, "index")
                        ? undefined
                        : get(action.payload, "index"),
                clickedClusterId: undefined,
                lastClickWasFromRanking: get(
                    action.payload,
                    "lastClickWasFromRanking"
                ),
            };
        },
        setHoveredCluster(state, action) {
            return {
                ...state,
                hoveredClusterId: action.payload,
            };
        },
        setSheet(state, action) {
            return {
                ...state,
                sheetId: action.payload.sheetId,
                sheetRange: action.payload.sheetRange,
            };
        },
        setClickedCluster(state, action) {
            return {
                ...state,
                clickedClusterId:
                    state.clickedClusterId === action.payload
                        ? undefined
                        : action.payload,
                clickedItemIndex: undefined,
            };
        },
        setHoveredItemIndex(state, action) {
            return {
                ...state,
                hoveredItemIndex: action.payload,
            };
        },
        setCustomisedOptions(state, action) {
            state.customisedOptions[action.payload.key] = action.payload.value;
            updateQuery({
                key: "customisedOptions",
                value: state.customisedOptions,
            });
        },
        setPopup(state, action) {
            return {
                ...state,
                popup: action.payload,
            };
        },
        resetSliders(state, action) {
            const newSliderWeigtings = state.sliderWeightings.map(x => ({
                ...x,
                lessIsBetter: false,
                value: 0,
            }));
            state.sliderWeightings = newSliderWeigtings;
            state.hoveredItemIndex = undefined;
            state.clickedItemIndex = undefined;
            state.hoveredClusterId = undefined;
            state.clickedClusterId = undefined;
        },
        setIndividualSliderLessIsBetter(state, action) {
            const { index, lessIsBetter } = action.payload;
            const item = state.sliderWeightings[index];
            const newSliderWeightedData = { ...item, lessIsBetter };

            state.sliderWeightings[index] = newSliderWeightedData;
            state.hoveredItemIndex = undefined;
            state.clickedItemIndex = undefined;
            state.hoveredClusterId = undefined;
            state.clickedClusterId = undefined;
            updateSliderWeightingQuery(state.sliderWeightings);
        },
        setIndividualSliderWeighting(state, action) {
            const { index, weighting } = action.payload;
            const item = state.sliderWeightings[index];
            const newSliderWeightedData = { ...item, value: weighting };

            state.sliderWeightings[index] = newSliderWeightedData;
            state.hoveredItemIndex = undefined;
            state.clickedItemIndex = undefined;
            state.hoveredClusterId = undefined;
            state.clickedClusterId = undefined;
            updateSliderWeightingQuery(state.sliderWeightings);
        },
    },
});
export default appSlice.reducer;
export const {
    setClickedCircle,
    setClickedCluster,
    setHoveredCluster,
    setHoveredItemIndex,
    setIndividualLessIsBetter,
    setIndividualSliderWeighting,
    setPopup,
    setTsneData,
    setSheet,
    setIndividualSliderLessIsBetter,
    setShowBanner,
    resetSliders,
    setCustomisedOptions,
} = appSlice.actions;

//instead of returning an object, we're returning a function that returns an object
// as opposed to fetching from component
export const fetchData = ({ sheetId, sheetRange }) => {
    return (dispatch, getState) => {
        dispatch(appSlice.actions.dataRequest());
        fetch(
            `/.netlify/functions/sheet/?sheetId=${sheetId}${
                sheetRange ? "&range=" + sheetRange : ""
            }`
        )
            .then(d => d.json())
            .then(json => {
                dispatch(appSlice.actions.dataSuccess(json));
            })
            .catch(err => {
                dispatch({ type: "DATA:FAILURE", payload: err });
                throw err;
            });
    };
};

const selectSliderWeightings = state => state.sliderWeightings;
const selectNormalisedData = state => state.normalisedData;

const selectWeightedData = createSelector(
    selectSliderWeightings,
    selectNormalisedData,
    (sliderWeightings, normalisedData) => {
        if (!normalisedData) return undefined;
        const weightedData = normalisedData.map(normaliseDatum => {
            const objWithWeightedValues = sliderWeightings.reduce(
                (acc, { name, value }) => {
                    return { ...acc, [name]: normaliseDatum[name] * value };
                },
                normaliseDatum
            );

            return objWithWeightedValues;
        });
        return weightedData;
    }
);

export const selectActiveWeighting = createSelector(
    selectSliderWeightings,
    sliderWeightings => {
        return sliderWeightings.filter(x => x.value !== 0);
    }
);

export const selectActiveCircle = state => {
    const itemIndex =
        state.hoveredItemIndex === undefined
            ? state.clickedItemIndex
            : state.hoveredItemIndex;
    if (itemIndex === undefined) return undefined;
    if (state.tsneData === undefined) return undefined;
    return { ...state.tsneData[itemIndex], index: itemIndex };
};

export const selectSortedData = createSelector(
    selectWeightedData,
    weightedData => {
        if (!weightedData) return undefined;

        const dataWithSortValue = weightedData.map(item => {
            const sortValue = Object.entries(item)
                .slice(1)
                .reduce((total, curr, i) => {
                    const [key,val] = curr
                    
                    // TODO: probably not a proper fix
                    let v = val
                    if(isNaN(val)) {
                        v = 0
                    }

                    return total + parseFloat(v);
                }, 0);
            return { ...item, sortValue };
        });

        return orderBy(
            dataWithSortValue,
            "sortValue",
            "desc"
        ).map((x, index) => ({ ...x, index }));
    }
);

export const selectDataForBackend = createSelector(
    selectActiveWeighting,
    selectSortedData,
    (sliderWeightings, sortedData) => {
        if (!sortedData) return undefined;

        const weightedDataCleaned = sortedData.map(datum => {
            const objWithWeightedValues = sliderWeightings.reduce(
                (acc, { name, value }) => {
                    return { ...acc, [name]: datum[name] };
                },
                { name: datum.name }
            );

            return objWithWeightedValues;
        });
        return weightedDataCleaned;
    }
);

export const selectActiveClusterId = state => {
    return state.hoveredClusterId === undefined
        ? state.clickedClusterId
        : state.hoveredClusterId;
};

const selectClusterMedians = createSelector(
    selectActiveWeighting,
    state => state.rawDataByName,
    state => state.tsneData,
    (activeWeightings, sortedData, tsneData) => {
        if (!activeWeightings || !tsneData) return undefined;
        if (tsneData[0].clusterId === undefined) return undefined;

        const groupedByCluster = groupBy(tsneData, "clusterId");
        const sortedDataByName = keyBy(sortedData, "name");
        const mediansForCluster = Object.entries(groupedByCluster).map(
            ([clusterId, data]) => {
                const dataForCluser = data.map(x => sortedDataByName[x.name]);
                const medians = activeWeightings.map(weighting => {
                    const median = calculateMedian(
                        dataForCluser.map(x => x[weighting.name])
                    );

                    return { name: weighting.name, median };
                });
                return { clusterId, mediansByName: keyBy(medians, "name") };
            }
        );
        return mediansForCluster;
    }
);

export const selectActiveClusterData = createSelector(
    selectActiveClusterId,
    selectActiveWeighting,
    selectClusterMedians,
    (clusterId, weightings, clusterMedians) => {
        if (!clusterMedians) return undefined;
        if (clusterId === undefined) return undefined;

        const activeClusterMedians = clusterMedians.find(
            x => parseInt(x.clusterId, 10) === clusterId
        );

        return {
            activeClusterMedians,
            clusterMedians,
        };
    }
);

export const selectActiveRawData = createSelector(
    selectActiveCircle,
    selectSortedData,
    state => state.rawDataByName,
    (selectActiveCircle, sortedData, rawDataByName) => {
        if (selectActiveCircle === undefined) {
            return undefined;
        }

        const { name } = sortedData[selectActiveCircle.index];
        return rawDataByName[name];
    }
);

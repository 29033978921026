import random from "lodash/random";

// color source
// Run this in console:
// Array.from(document.querySelectorAll('#vis svg #color-name')).map(x => x.__data__)
// removed white.
// https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
const CLUSTER_COLORS = [
    "#FFC600",
    "#EE7623",
    "#C31C4A",
    "#FF40B4",
    "#1A4282",
    "#00A0DF",
    "#23BAA6",
    "#266041",
    "#C7893E",
    "#8D634B",
    //Secondary
    "#FFA400",
    "#FF5100",
    "#93267F",
    "#C431AC",
    "#0071CE",
    "#00C1DE",
    "#009D4F",
    "#949300",
    "#BB6125",
    "#708E8A",
];
const CLUSTER_COLOR_NAME = [
    "Yellow",
    "Orange",
    "Crimson",
    "Pink",
    "Indigo",
    "Sky",
    "Teal",
    "Emerald",
    "Sepia",
    "Chocolate",
    //Secondary
    "Amber",
    "Red",
    "Violet",
    "Purple",
    "Blue",
    "Turqouise",
    "Green",
    "Moss",
    "Brown",
    "Ash",
];
export const getClusterColor = clusterId => {
    // circle couldn't be assigned to any cluster eg noice
    if (clusterId === null) {
        return "white";
    }
    // no cluster has been assign
    if (clusterId === undefined) {
        const randomIndex = random(0, CLUSTER_COLORS.length - 1, false);
        return CLUSTER_COLORS[randomIndex];
    }
    return CLUSTER_COLORS[clusterId];
};
export const getClusterName = clusterId => {
    return CLUSTER_COLOR_NAME[clusterId];
};

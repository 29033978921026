import React from "react";
import { connect } from "react-redux";
import {
    selectActiveRawData,
    selectActiveWeighting,
    selectActiveCircle,
} from "../ducks";
import { getClusterColor } from "../modules/cluster-colors";

const ItemFactoid = ({
    activeCircle,
    xScale,
    yScale,
    data,
    activeWeightings,
}) => {
    const color = getClusterColor(activeCircle.clusterId);
    return (
        <div>
            <h2 css={{ fontSize: 24, margin: 0 }}>
                <span
                    css={{
                        color: color === "white" ? "black" : color,
                    }}
                >
                    {activeCircle.index + 1}
                </span>{" "}
                {activeCircle.name}
            </h2>
            <div
                css={{
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    fontSize: 11,
                    marginTop: 10,
                }}
            >
                {activeWeightings.map(({ name, value }) => {
                    return (
                        <div
                            css={{
                                marginRight: 10,
                            }}
                            key={name}
                        >
                            <span css={{ fontWeight: "bold" }}>{name}:</span>{" "}
                            <span>{data[name] || 0}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default connect(state => ({
    data: selectActiveRawData(state),
    activeCircle: selectActiveCircle(state),
    activeWeightings: selectActiveWeighting(state),
}))(ItemFactoid);

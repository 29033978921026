import React from "react";
import Clipboard from "react-clipboard.js";
import { makeStyles } from "@material-ui/core/styles";

import Input from "./Input";
import { Copy } from "./Icons";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: "black",
    },
    tooltip: {
        backgroundColor: "black",
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

export default function SharePopupContent() {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <h3 css={{ marginTop: 0 }}>Share</h3>
            <div
                css={{
                    background: "none",
                    border: "none",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Input
                    css={{ paddingRight: 30 }}
                    type="text"
                    disabled
                    value={window.location.href}
                />

                <Clipboard
                    component="div"
                    data-clipboard-text={window.location.href}
                >
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div
                            css={{
                                width: 20,
                                position: "absolute",
                                right: 5,
                                top: "0",
                                bottom: "0",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <BootstrapTooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied!"
                            >
                                <Copy
                                    css={{ width: 20 }}
                                    onClick={() => {
                                        setOpen(true);
                                        setTimeout(() => {
                                            setOpen(false);
                                        }, 1500);
                                    }}
                                />
                            </BootstrapTooltip>
                        </div>
                    </ClickAwayListener>
                </Clipboard>
            </div>
        </div>
    );
}

import React from "react";
import { connect } from "react-redux";
import { setSheet, setPopup } from "../ducks";
import { colors } from "../modules/theme";
import Input from "./Input";
import { updateQuery } from "../modules/query-helpers";
import PopupWrapper from "./PopupWrapper";

function AddDataPopupContent({ sheetId, sheetRange, setSheet, setPopup }) {
    return (
        <PopupWrapper>
            <h2
                css={{
                    background: colors.yellow,
                    textAlign: "center",
                    fontWeight: "normal",
                    fontSize: 24,
                    padding: "20px",
                    marginTop: 0,
                }}
            >
                Add you own data
            </h2>
            <form
                css={{
                    display: "flex",
                    flexDirection: "column",
                }}
                onSubmit={e => {
                    e.preventDefault();
                    const idOrUrl = e.currentTarget[0].value;
                    let sheetId = idOrUrl;
                    if (idOrUrl.includes("/")) {
                        const split = idOrUrl.split("/");
                        const indexOfId = split.indexOf("d") + 1;
                        sheetId = split[indexOfId];
                    }

                    const sheetRange = e.currentTarget[1].value;
                    setSheet({ sheetRange, sheetId });
                    updateQuery({ key: "sheetId", value: sheetId });
                    updateQuery({ key: "sheetRange", value: sheetRange });

                    setPopup();
                }}
            >
                <label css={{ marginRight: 10, fontWeight: "bold" }}>
                    Google Sheet URL or ID
                    <Input css={{ paddingRight: 0 }} defaultValue={sheetId} />
                </label>

                <label css={{ fontWeight: "bold", marginTop: 20 }}>
                    Sheet range (optional)
                    <Input
                        placeholder={"eg. A1:D100"}
                        css={{ paddingRight: 0 }}
                        defaultValue={sheetRange}
                    />
                </label>
                <button
                    css={{
                        width: 100,
                        background: "white",
                        padding: 10,
                        fontWeight: "bold",
                        fontSize: 14,
                        border: "2px solid black",
                        marginTop: 25,
                        cursor: "pointer",
                    }}
                    type="submit"
                >
                    Add
                </button>
            </form>
        </PopupWrapper>
    );
}
export default connect(
    state => ({
        sheetId: state.sheetId,
        sheetRange: state.sheetRange,
    }),
    { setSheet, setPopup }
)(AddDataPopupContent);

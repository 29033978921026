import React from "react";
import { connect } from "react-redux";
import { setCustomisedOptions } from "../ducks";
import { TwitterPicker } from "react-color";

function CustomisePopup({ setCustomisedOptions }) {
    return (
        <div>
            <h3 css={{ marginTop: 0 }}>Customise</h3>

            <div
                css={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                }}
            >
                <div>
                    <p>Pick a background color</p>
                    <TwitterPicker
                        onChangeComplete={({ hex }) => {
                            setCustomisedOptions({
                                key: "sidebarBgColor",
                                value: hex,
                            });
                        }}
                    />
                </div>
                <div>
                    <p>Pick a slider color</p>
                    <TwitterPicker
                        onChangeComplete={({ hex }) => {
                            setCustomisedOptions({
                                key: "sliderColor",
                                value: hex,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
export default connect(null, { setCustomisedOptions })(CustomisePopup);

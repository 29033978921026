import React from "react";
import { setPopup } from "../ducks";
import { connect } from "react-redux";
import { Cross } from "./Icons";
import { mobile } from "../modules/theme";

function PopupWrapper(props) {
    return (
        <div
            onClick={() => {
                props.setPopup();
            }}
            css={{
                zIndex: 100,
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                background: `rgba(0,0,0,.8)`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                onClick={e => e.stopPropagation()}
                css={{
                    background: "white",
                    display: "flex",
                    flexDirection: "column",
                    padding: 50,
                    maxWidth: 900,
                    width: "80%",
                    position: "relative",
                    [mobile]: {
                        padding: "40px 20px",
                        width: "95%",
                    },
                }}
            >
                <Cross
                    css={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        width: 25,
                        height: 25,
                        cursor: "pointer",
                    }}
                    onClick={e => props.setPopup()}
                />
                {props.children}
            </div>
        </div>
    );
}
export default connect(null, { setPopup })(PopupWrapper);
